import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import FacilityEdit from "@components/facilities/Edit"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "facilities.edit"
}

const FacilityEditPage = (props) => (
  <>
    <Seo title="Edit Facility" />
    <div className="main-wrapper">
      <Sidebar menuActive="settings"
        action="Edit" />
      <FacilityEdit
        menu="Edit Facilities"
        parentPath="facilities"
        groupMenu="settings"
        pageType="Edit"
        additionalParams={AdditionalParams} />
    </div>
  </>
)

export default FacilityEditPage;
